import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import './App.css';
//'Waiting list' imports
import PhoneNumberForWaiting from "./Pages/5-Waiting-list-flow/Step1-PhoneNumberForWaiting";
import ConfirmedWaiting from "./Pages/5-Waiting-list-flow/Step2-ConfirmedWaiting";

import WhoInvitedMe from "./Pages/6-InviteFriends/Step1-WhoInvitedMe"
import GetInvited from './Pages/6-InviteFriends/Step2-GetInvited';
import ConfirmedInvitation from './Pages/6-InviteFriends/Step3-ConfirmedInvitation';

const LOCAL_STORAGE_KEY_PHONE_NUMBER = 'h.pnumb'
const LOCAL_STORAGE_INVITER_PHONE_NUMBER = 'h.invpnumb'

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

function App() {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [inviterPhoneNumber, setInviterPhoneNumber] = useState("")

  // Page tracking
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

    //Load phone number from local storage on page load
    useEffect(() => {
      const storedPhoneNumber = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PHONE_NUMBER) || '""')
      if (storedPhoneNumber) setPhoneNumber(storedPhoneNumber)
    }, [])

      //Load inviter phone number from local storage on page load
      useEffect(() => {
        const storedInviterPhoneNumber = JSON.parse(localStorage.getItem(LOCAL_STORAGE_INVITER_PHONE_NUMBER) || '""')
        if (storedInviterPhoneNumber) setInviterPhoneNumber(storedInviterPhoneNumber)
      }, [])

  return (
      <Router>
        <Routes>
          {/* 'Waiting list' imports */}
          <Route path="/waiting-list" element={
            <PhoneNumberForWaiting
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              LOCAL_STORAGE_KEY_PHONE_NUMBER={LOCAL_STORAGE_KEY_PHONE_NUMBER}
            />
          } />
          <Route path="/confirmed-waiting" element={
            <ConfirmedWaiting />
          } />

          {/* Invite friends imports */}
          <Route path="/app" element={
            <WhoInvitedMe
              inviterPhoneNumber={inviterPhoneNumber}
              setInviterPhoneNumber={setInviterPhoneNumber}
              LOCAL_STORAGE_INVITER_PHONE_NUMBER={LOCAL_STORAGE_INVITER_PHONE_NUMBER}         
            />
          } />
          <Route path="/app/complete-your-invite" element={
            <GetInvited
              inviterPhoneNumber={inviterPhoneNumber}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          } />
          <Route path="/app/invite-completed" element={
            <ConfirmedInvitation
            />
          } />

        </Routes>
      </Router>    
  )
}

export default App;
