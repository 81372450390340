import Navigation from '../../Components/Navigation';

const ConfirmedWaiting = () => {
    const linkBack = ""
    const linkSkip = ""

  return (
    <div className='flex h-screen flex-col bg-[#000]'>
      <div className='flex flex-col justify-center items-center h-5/6 p-5 bg-[#000]'>
        <Navigation linkBack={linkBack} linkSkip={linkSkip} showLinkBack={false}  showLinkSkip={false}/>

      <div className='flex flex-col items-center text-center text-[#fff]'>
          <div className='text-xl  mb-5'>
            Du står nu på väntelistan. Vi smsar dig så fort en plats blir tillgänglig! 🎉
          </div>
          <div className='text-base'>
            Vill du lära dig mer om Houtive under tiden, besök <a className='underline' href="https://www.houtive.com">houtive.com</a>. Tack! 🙏
          </div>
      </div>
    </div>
  </div>
  );
}

export default ConfirmedWaiting;
