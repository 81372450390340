import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../Components/Navigation';
import ButtonNext from '../../Components/ButtonNext';
import PhoneInput from 'react-phone-number-input/input'

const GetInvited = ( { inviterPhoneNumber, phoneNumber, setPhoneNumber } ) => {
  const [buttonDisabled, setButtonDisable] = useState(true)
  const [timeoutTrigger, setTimeoutTrigger] = useState(false)
  const [submitPressed, setSubmitPressed] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const linkBack = ""
  const linkSkip = ""
  const phoneNumberStyle = { //Den här css ska över i css-fil. OBS: Den här behövs för att det inte ska bli lagg i css-laddning
    border: 'none',
    padding: '10px 10px 10px 10px',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    borderRadius: '40px',
    color: "#000"
  }
  const saveInviteDataUrl = process.env.REACT_APP_CLOUD_FUNCTION_API_SAVEINVITEDATA

  //Generate error message when upload takes to long
  useEffect(() => {
    if (!submitPressed) return
    const timer = setTimeout(() => {
      if (error) return
      setErrorMessage("Kontrollera uppkopplingen.")
      setError(true)
    }, 5000);
    return () => {
      clearTimeout(timer);
    }
  }, [submitPressed, error, timeoutTrigger])

  const handleSaveToFirestore = async () => {
    try {
      const response = await fetch(saveInviteDataUrl,{
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviterPhoneNumber: inviterPhoneNumber,
          invitedPhoneNumber: phoneNumber
        }),
      })
      const savedSuccessfully = await response.json()
      if (savedSuccessfully) {
        navigate("../app/invite-completed") //Then navigate to next page   
      } else {
        setErrorMessage("Något gick fel. Försök igen.")
        setError(true)
      }
    } catch (e) {
      setErrorMessage("Något gick fel. Försök igen.")
      setError(true)
    }
  }

  const handleSubmit = (event) =>{
    event.preventDefault()
    setError(false)
    //Ensure error message is visible if upload takes to long
    setSubmitPressed(true)
    setTimeoutTrigger(current => !current)
    handleSaveToFirestore()
  }

  useEffect(() => {
    if (phoneNumber === undefined) {
      setButtonDisable(true)
    } else {
      if (phoneNumber.length < 12 ) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }
    }      
  }, [phoneNumber])

  return (
    <div className='flex h-screen flex-col bg-[#000]'>

      <div className='flex justify-between flex-col h-5/6 p-5 bg-[#000]'>
        <Navigation
          linkBack={linkBack}
          linkSkip={linkSkip}
          showLinkBack={false} 
          showLinkSkip={false}
        />

        <div className='flex justify-center text-xl text-[#fff]'>
          Vad är ditt telefonnummer?
        </div>

        <div>
          <div className='flex justify-center'>
            <PhoneInput
                country="SE"
                style={phoneNumberStyle}
                placeholder={"+467xxxxxxxx"}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
          </div>
          <div className='text-center text-xs text-[#fff] mt-2'>
              OBS: Du använder ditt nr för att logga in
          </div>  
        </div>
        
        <div className='flex justify-center'>
          <ButtonNext
            handleSubmit={handleSubmit}
            buttonDisabled={buttonDisabled}
            error={error}
            errorMessage={errorMessage}
            buttonText={"Nästa ➔"}
          />
        </div>
      </div>
    </div>
  );
}

export default GetInvited;
