import React from 'react';
import './Navigation.css';
import { Link } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function Navigation( {linkBack, linkSkip, showLinkBack, showLinkSkip} ) {
  return (
    <div className='inline-flex mx-0 justify-between'>
      <div className="flex">
        { showLinkBack ? (
          <Link to={linkBack}>
            <ArrowBackIosNewIcon htmlColor='#fff' />
          </Link>
        ) : null }      
      </div>
      <div className="flex">
        { linkSkip ? (
          <Link to={linkSkip}>Hoppa över</Link>
        ) : null }
      </div>
</div>
  );
}

export default Navigation;
