import Navigation from '../../Components/Navigation';

const ConfirmedInvitation = () => {
    const linkBack = ""
    const linkSkip = ""

  return (
    <div className='flex h-screen flex-col bg-[#000]'>
      <div className='flex flex-col justify-center items-center h-5/6 p-5 bg-[#000]'>
        <Navigation linkBack={linkBack} linkSkip={linkSkip} showLinkBack={false}  showLinkSkip={false}/>

      <div className='flex flex-col items-center text-center text-[#fff]'>
          <div className='text-xl  mb-5'>
            Registrerad! 🎉
          </div>
          <div className='text-base'>
            Du kan nu ladda ner appen:
          </div>
      </div>
    </div>
  </div>
  );
}

export default ConfirmedInvitation;
