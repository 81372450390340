import React from 'react';

function ButtonNext({handleSubmit, buttonDisabled, buttonText, error, errorMessage}) {
  return (
    <div className='flex flex-col items-center'>
        <div className='text-[#BA891E]'>
            {error ? (<p>{errorMessage}</p>) : <p></p>}
        </div>   
        <button
        onClick={handleSubmit}
        disabled={buttonDisabled}
        className="py-2.5 px-5 m-2.5 rounded-full text-base text-black bg-[#1db91d] duration-200 hover:bg-[#326532] disabled:bg-[#202020]">
          {buttonText}
        </button>
  </div>    
    );
}

export default ButtonNext;
